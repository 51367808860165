<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="group">
      <GroupHeader :group="group" />

      <vue-markdown v-if="group.description" class="markdown px-4 pt-8" :html="false">{{group.description }}</vue-markdown>

      <div v-if="group.events && group.events.length >= 0">
        <v-card-title v-if="group.type=='SERIES'" class="subtitle">Events and challenges in this series</v-card-title>
        <v-card-title v-if="group.type=='ORGANIZATION'" class="subtitle">Events and challenges by {{group.name}}</v-card-title>
        <EventGrid :items="group.events" showLogos class="mx-4 mb-4"></EventGrid>
      </div>      
      <br/>
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import VueMarkdown from '@/components/VueMarkdown.vue'
import GroupHeader from "@/components/GroupHeader.vue";
import EventGrid from "@/components/EventGrid.vue";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "ProfileGroupsView",
  components: {
    EventGrid,
    GroupHeader,
    VueMarkdown,
  },
  props: {
  },
  data() {
    return {
      group: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getGroup(id) {
      var response = await profileService.getGroup(id);
      this.group = response.data;

      if (this.group == null || this.group.type != this.$route.meta.groupType) {
        this.$router.push('/');
        return;
      }

      var meta = {
        title: this.group.name + ' on ' + tenant.name,
        description: (this.group.description || this.group.name) + ' on ' + tenant.title,
        image: this.group.logo || this.group.img,
      };
      EventBus.$emit('page-header-change', meta);

    },

    async getProfile() {
      if (this.user) {
        await this.getGroup(this.$route.params.id);
      }
    },
  },
  computed: {
    breadcrumbItems() {
      return this.group == null ? [] : [
        { text: this.group.name, disabled: true },
      ]
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

